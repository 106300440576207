<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>修改密码</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="changePassword">
              <v-text-field v-model="password" label="当前密码" type="password" required></v-text-field>
              <v-text-field v-model="newPassword" label="新密码" type="password" required></v-text-field>
              <v-btn type="submit" color="primary">修改密码</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>退出登录</v-card-title>
          <v-card-text>
            <v-btn color="error" @click="logout">退出登录</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
// Existing code...
data() {
  return {
    // Existing data...
    username: this.$store.state.username,
    password: '',
    newPassword: '',
    snackbar: false,
    snackbarMessage: '',
    snackbarColor: 'success'
  }
},
methods: {
  // Existing methods...
  async changePassword() {
    try {
      const params = new URLSearchParams();
      params.append('name', this.username);
      params.append('password', this.password);
      params.append('newPassword', this.newPassword);

      const response = await axios.post('https://qidafang.com/wikiapi/changePassword', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      if (response.data.code === 200) {
        this.snackbarMessage = '修改成功';
        this.snackbarColor = 'success';
      } else {
        this.snackbarMessage = '修改失败: ' + response.data.message;
        this.snackbarColor = 'error';
      }
      this.snackbar = true;
    } catch (error) {
      this.snackbarMessage = '请求失败: ' + error.message;
      this.snackbarColor = 'error';
      this.snackbar = true;
    }
  },
  logout() {
    this.$store.commit('logout');
    this.$router.push('/');
  },
}
};
</script>

<style scoped>
</style>